@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  margin: 0%;
  font-family: "Inter", sans-serif;
}


.hide-scrollbar {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
}
.hide-scrollbar::-webkit-scrollbar { /** WebKit */
  display: none;
}